import { createCompanySpecificIndustryApi } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Modal } from "@/components/elements/Modal";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CreateCompanySpecificIndustryFormTable } from "@/features/master-data/components/CompanySpecificIndustry/CreateCompanySpecificIndustryFormTable";
import { masterDataCreateCompanySpecificIndustrySchema } from "@/features/master-data/schema/masterDataCompanySpecificIndustrySchema";
import { TMasterCompanySpecificIndustryCreateForm } from "@/features/master-data/types/masterDataCompanySpecificIndustryForm";
import { setErrorForm, showError } from "@/utils/error";
import { toast } from "@/utils/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";

type TCreateCompanySpecificIndustryFormModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateCompanySpecificIndustryFormModal: FC<TCreateCompanySpecificIndustryFormModalProps> = ({ onClose, isOpen }) => {
  const queryClient = useQueryClient();

  const methods = useForm<TMasterCompanySpecificIndustryCreateForm>({
    resolver: yupResolver(masterDataCreateCompanySpecificIndustrySchema),
    mode: "onBlur",
    defaultValues: {
      companySpecificIndustry: "",
      companySpecificIndustryVisaCsvCode: "",
      companySpecificBusinessClassifications: [
        {
          companySpecificBusinessClassificationName: "",
        },
      ],
    },
  });

  const { control } = methods;

  const onSubmit = async (data: TMasterCompanySpecificIndustryCreateForm) => {
    try {
      const rs = await createCompanySpecificIndustryApi({
        companySpecificIndustry: data.companySpecificIndustry,
        companySpecificIndustryVisaCsvCode: data.companySpecificIndustryVisaCsvCode,
        companySpecificBusinessClassifications: data.companySpecificBusinessClassifications.map((item) => ({
          companySpecificBusinessClassificationName: item.companySpecificBusinessClassificationName,
        })),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY] });
      onClose();
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Modal title="追加" isOpen={isOpen} enableFooter={false} onClose={onClose} enableCloseIcon={false}>
      <Form methods={methods} onSubmit={onSubmit} schema={masterDataCreateCompanySpecificIndustrySchema}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustry">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={6}>
            <FormField control={control} name="companySpecificIndustryVisaCsvCode">
              <Input />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="body14Bold">業務区分（会社情報用）</Typography>
              <CreateCompanySpecificIndustryFormTable />
            </Stack>
          </Grid>
        </Grid>
        <Stack flexDirection="row" justifyContent="center" gap="24px">
          <Button variant="outline" onClick={onClose}>
            キャンセル
          </Button>
          <Button type="submit" disabled={methods.watch("companySpecificBusinessClassifications").length === 0}>
            保存
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};
