import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { useToast } from "@/hooks/useToast";
import { setErrorForm, showError } from "@/utils/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { text } from "@/theme/colors";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { AppRoutes } from "@/routes/config";
import { TMaintenanceForm } from "@/features/system-setting/types/maintenanceForm";
import { InputDateTimePicker } from "@/components/elements/InputDateTimePicker";
import { maintenanceSchema } from "@/features/system-setting/schema/maintenanceSchema";
import { convertMaintenanceSettingFormToUpdateReq } from "@/features/system-setting/utils/converts";
import { updateMaintenanceSetting } from "@/api/services/admin/system-setting";
import { Select } from "@/components/elements/Select";
import { dayjs } from "@/utils/dayjs";

const initForm: TMaintenanceForm = {
  maintenanceMode: "",
  modeStartDateTime: undefined,
  modeEndDateTime: undefined,
};

type TMaintenanceFormProps = {
  readonly?: boolean;
  previousData?: Partial<TMaintenanceForm>;
};

export const MaintenanceForm: FC<TMaintenanceFormProps> = ({ readonly, previousData }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TMaintenanceForm>({
    defaultValues: previousData ?? initForm,
    resolver: yupResolver(maintenanceSchema),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TMaintenanceForm) => {
    try {
      const payload = convertMaintenanceSettingFormToUpdateReq(data);
      console.log(payload.modeStartDateTime);
      payload.modeStartDateTime = payload.modeStartDateTime
        ? dayjs(payload.modeStartDateTime.replace("Z", "")).local().tz("Asia/Tokyo").toISOString()
        : null;
      payload.modeEndDateTime = payload.modeEndDateTime
        ? dayjs(payload.modeEndDateTime.replace("Z", "")).local().tz("Asia/Tokyo").toISOString()
        : null;
      const rs = await updateMaintenanceSetting(payload);
      navigate(AppRoutes.maintenanceSetting);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SYSTEM_SETTING.FETCH_MAINTENANCE_DETAIL] });
    } catch (error) {
      showError(error);
      setErrorForm(methods, error);
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit} schema={maintenanceSchema}>
      <Stack paddingTop={3} gap={3}>
        <Typography variant="sub16Semi">メンテナンスツール</Typography>
        <Typography variant="body14" color={text.accent}>
          緊急時を除き、必ずお客様にメンテナンス計画のお知らせをアナウンスしてから実施してください。
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="maintenanceMode">
                <Select enumName="EMaintenanceMode" />
              </FormField>
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="modeStartDateTime">
                <InputDateTimePicker timezone="Asia/Tokyo" />
              </FormField>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap={3}>
              <FormField control={control} name="modeEndDateTime">
                <InputDateTimePicker timezone="Asia/Tokyo" />
              </FormField>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {!readonly && (
        <Box textAlign="center" mt={6}>
          <Button type="submit">設定</Button>
        </Box>
      )}
    </Form>
  );
};
