/* eslint-disable @typescript-eslint/no-explicit-any */
import { TGetConvertRequestOutputDto } from "@/api/services/admin/convert-request/dtos/get-convert-request.dto";
import { useGetCompanySpecificIndustryList, useGetNationalityList } from "@/api/services/global/master";
import { Button } from "@/components/elements/Button";
import { Copyable } from "@/components/elements/Copyable";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { background, divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { Stack, styled, Typography } from "@mui/material";
import beautify from "js-beautify";
import { useEffect, useState } from "react";
import { findLabelByValue } from "@/utils/object";
import { useEnum } from "@/api/services/global/enum";
import { SUPPORT_IMPLEMENTATION_STATUS } from "@/types/enum";
import { ERetirementDocumentName } from "@/features/convert/enums/retirement";
import { getFullName, getFullAddress } from "@/utils/string";
import { useGetEncryptSecretKeyApi } from "@/api/services/global/constant";
import { decrypt } from "@/utils/pieces";

type TConvertJSONProps = {
  convertRequestData: TGetConvertRequestOutputDto;
};

export const ConvertJSON = ({ convertRequestData }: TConvertJSONProps) => {
  const { data: companySpecificIndustryList } = useGetCompanySpecificIndustryList();
  const { data: nationalityList } = useGetNationalityList();
  const { data: documentType } = useEnum("EDocumentType");
  const { data: supportImplementationStatusList } = useEnum("ESupportImplementationStatus");
  const { data: employmentContractModificationType } = useEnum("EEmploymentContractModificationType");
  const { data: modifyContractModificationReason } = useEnum("EModifyContractModificationReason");
  const { data: modifyContractChangeDispatchLocationReasons } = useEnum("EModifyContractChangeDispatchLocationReasons");
  const { data: modifyContractTerminateReason } = useEnum("EModifyContractTerminateReason");
  const { data: modifyContractTerminateReasonForCompany } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: modifyContractTerminateReasonForEmployee } = useEnum("EModifyContractTerminateReasonForEmployee");
  const { data: supportRecipient } = useEnum("ESupportRecipient");
  const { data: supportSpecifiedSkill } = useEnum("ESupportSpecifiedSkill");
  const { data: supportRegistered } = useEnum("ESupportRegistered");
  const { data: supportOutsourcingContractModificationType } = useEnum("ESupportOutsourcingContractModificationType");
  const { data: terminateContractMajorCategoryReason } = useEnum("ETerminateContractMajorCategoryReason");
  const { data: terminateContractSubcategoryReason } = useEnum("ETerminateContractSubcategoryReason");
  const { data: supportContractSubcategoryReason } = useEnum("ESupportContractSubcategoryReason");
  const { data: retirementReasonTypeOptions } = useEnum("ERetirementReasonType");
  const { data: modifyContractTerminateReasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: modifyContractTerminateReasonForEmployeeOptions } = useEnum("EModifyContractTerminateReasonForEmployee");
  const { data: currentSituationOptions } = useEnum("ECurrentSituation");
  const { data: planToContinueActivityOptions } = useEnum("EPlanToContinueActivity");
  const { data: measureOptions } = useEnum("EMeasure");
  const { data: specificCircumstanceOptions } = useEnum("ESpecificCircumstance");
  const { data: specificCircumstanceContentOptions } = useEnum("ESpecificCircumstanceContent");
  const { data: anyConsultationOptions } = useEnum("EAnyConsultation");
  const { data: anyConsultationContentOptions } = useEnum("EAnyConsultationContent");
  const { data: planToChangeJobOptions } = useEnum("EPlanToChangeJob");
  const { data: planToChangeJobContentOptions } = useEnum("EPlanToChangeJobContent");
  const { data: supportTypeOptions } = useEnum("ESupportType");
  const { data: supportContentOptions } = useEnum("ESupportContent");
  const { data: reasonReturnHomeOptions } = useEnum("EReasonReturnHome");
  const { data: reasonReturnHomeContentOptions } = useEnum("EReasonReturnHomeContent");
  const { data: planDateReturnOptions } = useEnum("EPlanDateReturn");
  const { data: planDateReturnContentOptions } = useEnum("EPlanDateReturnContent");
  const { data: planToMoveOptions } = useEnum("EPlanToMove");
  const { data: planToMoveContentOptions } = useEnum("EPlanToMoveContent");
  const { data: providedLegalProtectionOptions } = useEnum("EProvidedLegalProtection");
  const { data: secretKey } = useGetEncryptSecretKeyApi();

  useEffect(() => {
    setDataJSON(beautify(JSON.stringify(convertJsonData(convertRequestData))));
  }, [
    convertRequestData,
    companySpecificIndustryList,
    nationalityList,
    supportImplementationStatusList,
    employmentContractModificationType,
    modifyContractModificationReason,
    modifyContractChangeDispatchLocationReasons,
    modifyContractTerminateReason,
    modifyContractTerminateReasonForCompany,
    modifyContractTerminateReasonForEmployee,
    supportRecipient,
    supportSpecifiedSkill,
    supportRegistered,
    supportOutsourcingContractModificationType,
    terminateContractMajorCategoryReason,
    // terminateContractSubcategoryReason,
    // supportContractSubcategoryReason,
    // retirementReasonTypeOptions,
    // modifyContractTerminateReasonForCompanyOptions,
    // modifyContractTerminateReasonForEmployeeOptions,
    // currentSituationOptions,
    // planToContinueActivityOptions,
    // measureOptions,
    // specificCircumstanceOptions,
    // specificCircumstanceContentOptions,
    // anyConsultationOptions,
    // anyConsultationContentOptions,
    // planToChangeJobOptions,
    // planToChangeJobContentOptions,
    // supportTypeOptions,
    // supportContentOptions,
    // reasonReturnHomeOptions,
    // reasonReturnHomeContentOptions,
    // planDateReturnOptions,
    // planDateReturnContentOptions,
    // planToMoveOptions,
    // planToMoveContentOptions,
    // providedLegalProtectionOptions,
    secretKey,
  ]);

  const convertJsonData = (convertRequestData: TGetConvertRequestOutputDto) => {
    if (convertRequestData.documentType === "regular") {
      return convertJsonDataForRegular(convertRequestData);
    }
    if (convertRequestData.documentType === "any_time") {
      return convertJsonDataForAnytime(convertRequestData);
    }
    if (convertRequestData.documentType === "retirement") {
      return convertJsonDataForRetirement(convertRequestData);
    }
    return convertRequestData;
  };

  const convertJsonDataForRegular = (convertRequestData: TGetConvertRequestOutputDto) => {
    const convertJson = convertRequestData.json as unknown as any;
    const jsonData = {
      convertDate: dayjs(convertRequestData.createdAt).format(COMPACT_DATE_FORMAT),
      convertStatus: convertRequestData.status,
      category: findLabelByValue(documentType, convertRequestData.documentType),
      name: convertRequestData.documentName,
      company: {
        companyCode: convertRequestData.companyCode,
        companyName: convertRequestData.companyName,
        companyUserName: convertRequestData.companyUserName,
        companyNote: convertRequestData.companyNote || null,
      },
      account: {
        id: convertRequestData.accountId,
        password: secretKey ? decrypt(convertRequestData.accountPassword, secretKey) : "",
      },
    } as any;

    if (convertRequestData.documentName === "acceptance_activity") {
      jsonData.name = "受入れ・活動状況に係る届出";
      jsonData.data = {
        documentInfo: {
          year: convertJson.documentInfo.year,
          quarter: convertJson.documentInfo.quarter,
          companyName: convertJson.documentInfo.companyName,
          companyFuriganaName: convertJson.documentInfo.companyFuriganaName,
          companySpecificIndustry:
            companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry ||
            null,
          companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber,
          companyZipCode: convertJson.documentInfo.companyZipCode
            ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
            : null,
          companyProvince: convertJson.documentInfo.companyProvince,
          companyAddress: convertJson.documentInfo.companyAddress,
          companyTelNumber: convertJson.documentInfo.companyTelNumber,
          companySupporterName: convertJson.documentInfo.companySupporterName || null,
          companySupporterTelNumber: convertJson.documentInfo.companySupporterTelNumber || null,
          companySupporterPosition: convertJson.documentInfo.companySupporterPosition || null,
          specificSkillTotalRegisteredEmployeeCount: convertJson.documentInfo.specificSkillTotalRegisteredEmployeeCount,
          specificSkillTotalNewEmployeeCount: convertJson.documentInfo.specificSkillTotalNewEmployeeCount,
          specificSkillTotalVoluntaryResignedEmployeeCount: convertJson.documentInfo.specificSkillTotalVoluntaryResignedEmployeeCount,
          specificSkillTotalLayoffEmployeeCount: convertJson.documentInfo.specificSkillTotalLayoffEmployeeCount,
          specificSkillTotalMissingEmployeeCount: convertJson.documentInfo.specificSkillTotalMissingEmployeeCount,
          specificSkillJapanRegisteredEmployeeCount: convertJson.documentInfo.specificSkillJapanRegisteredEmployeeCount,
          specificSkillJapanNewEmployeeCount: convertJson.documentInfo.specificSkillJapanNewEmployeeCount,
          specificSkillJapanVoluntaryResignedEmployeeCount: convertJson.documentInfo.specificSkillJapanVoluntaryResignedEmployeeCount,
          specificSkillJapanVoluntaryLayoffEmployeeCount: convertJson.documentInfo.specificSkillJapanVoluntaryLayoffEmployeeCount,
          specificSkillForeignRegisteredEmployeeCount: convertJson.documentInfo.specificSkillForeignRegisteredEmployeeCount,
          specificSkillForeignNewEmployeeCount: convertJson.documentInfo.specificSkillForeignNewEmployeeCount,
          specificSkillForeignVoluntaryResignedEmployeeCount: convertJson.documentInfo.specificSkillForeignVoluntaryResignedEmployeeCount,
          specificSkillForeignVoluntaryLayoffEmployeeCount: convertJson.documentInfo.specificSkillForeignVoluntaryLayoffEmployeeCount,
          specificSkillTotalRegisteredEmployeeTwoCount: convertJson.documentInfo.specificSkillTotalRegisteredEmployeeTwoCount,
          specificSkillTotalNewEmployeeTwoCount: convertJson.documentInfo.specificSkillTotalNewEmployeeTwoCount,
          specificSkillTotalVoluntaryResignedEmployeeTwoCount: convertJson.documentInfo.specificSkillTotalVoluntaryResignedEmployeeTwoCount,
          specificSkillTotalLayoffEmployeeTwoCount: convertJson.documentInfo.specificSkillTotalLayoffEmployeeTwoCount,
          specificSkillTotalMissingEmployeeTwoCount: convertJson.documentInfo.specificSkillTotalMissingEmployeeTwoCount,
          specificSkillJapanRegisteredEmployeeTwoCount: convertJson.documentInfo.specificSkillJapanRegisteredEmployeeTwoCount,
          specificSkillJapanNewEmployeeTwoCount: convertJson.documentInfo.specificSkillJapanNewEmployeeTwoCount,
          specificSkillJapanVoluntaryResignedEmployeeTwoCount: convertJson.documentInfo.specificSkillJapanVoluntaryResignedEmployeeTwoCount,
          specificSkillJapanVoluntaryLayoffEmployeeTwoCount: convertJson.documentInfo.specificSkillJapanVoluntaryLayoffEmployeeTwoCount,
          specificSkillForeignRegisteredEmployeeTwoCount: convertJson.documentInfo.specificSkillForeignRegisteredEmployeeTwoCount,
          specificSkillForeignNewEmployeeTwoCount: convertJson.documentInfo.specificSkillForeignNewEmployeeTwoCount,
          specificSkillForeignVoluntaryResignedEmployeeTwoCount: convertJson.documentInfo.specificSkillForeignVoluntaryResignedEmployeeTwoCount,
          specificSkillForeignVoluntaryLayoffEmployeeTwoCount: convertJson.documentInfo.specificSkillForeignVoluntaryLayoffEmployeeTwoCount,
          nonSpecificSkillRegisteredEmployeeCount: convertJson.documentInfo.nonSpecificSkillRegisteredEmployeeCount,
          nonSpecificSkillNewEmployeeCount: convertJson.documentInfo.nonSpecificSkillNewEmployeeCount,
          acceptanceActivityImplementationCost: convertJson.documentInfo.acceptanceActivityImplementationCost,
          acceptanceActivityPrepareCost: convertJson.documentInfo.acceptanceActivityPrepareCost,
        },
        employeesInfo: convertJson.employeesInfo.map((employee: any) => ({
          year: employee.year,
          quarter: employee.quarter,
          month: employee.month,
          no: employee.no,
          employeeFullName: employee.employeeFullName,
          employeeNationality: nationalityList.find((item) => item.id == employee.employeeNationalityId)?.nationalityName || null,
          employeeBirthday: dayjs(employee.employeeBirthday).format(COMPACT_DATE_FORMAT),
          employeeGender: employee.employeeGender,
          employeeResidenceCardNumber: employee.employeeResidenceCardNumber,
          employeeZipCode: employee.zipCode ? employee.zipCode.slice(0, 3) + "-" + employee.zipCode.slice(3) : null,
          employeeAddress: employee.employeeAddress || null,
          isChangeWorkingLocation: employee.isChangeWorkingLocation,
          isChangeDispatchPlace: employee.isChangeDispatchPlace,
          isChangeWorkingDetail: employee.isChangeWorkingDetail,
          hasComparisonEmployee: employee.hasComparisonEmployee,
          workingDayCount: employee.workingDayCount,
          totalAmountPaid: employee.totalAmountPaid,
          netAmountPaid: employee.netAmountPaid,
          legalDeductionAmount: employee.legalDeductionAmount,
        })),
      };

      jsonData.data.documentInfo.companySpecificIndustry = companySpecificIndustryList.find(
        (item) => item.id == convertJson.documentInfo.companySpecificIndustryId,
      )?.companySpecificIndustry;
    }

    if (convertRequestData.documentName === "life_support") {
      jsonData.name = "支援実施状況に係る届出";
      jsonData.data = {
        documentInfo: {
          year: convertJson.documentInfo.year,
          quarter: convertJson.documentInfo.quarter,
          companyName: convertJson.documentInfo.companyName,
          companyFuriganaName: convertJson.documentInfo.companyFuriganaName || null,
          companySpecificIndustry:
            companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry ||
            null,
          companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber,
          companyZipCode: convertJson.documentInfo.companyZipCode
            ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
            : null,
          companyProvince: convertJson.documentInfo.companyProvince,
          companyAddress: convertJson.documentInfo.companyAddress,
          companyTelNumber: convertJson.documentInfo.companyTelNumber,
          companySupporterName: convertJson.documentInfo.companySupporterName || null,
          companySupporterTelNumber: convertJson.documentInfo.companySupporterTelNumber || null,
          airportPickup: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.airportPickup),
          ...(convertJson.documentInfo.airportPickup === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            airportPickupReason: convertJson.documentInfo.airportPickupReason,
          }),
          seeOffAirport: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.seeOffAirport),
          ...(convertJson.documentInfo.seeOffAirport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            seeOffAirportReason: convertJson.documentInfo.seeOffAirportReason,
          }),
          supportLivelihood: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.supportLivelihood),
          ...(convertJson.documentInfo.supportLivelihood === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            supportLivelihoodReason: convertJson.documentInfo.supportLivelihoodReason,
          }),
          supportLifeOrientation: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.supportLifeOrientation),
          ...(convertJson.documentInfo.supportLifeOrientation === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            supportLifeOrientationReason: convertJson.documentInfo.supportLifeOrientationReason,
          }),
          accompanyToRelevantInstitutionsAndOtherSupport: findLabelByValue(
            supportImplementationStatusList,
            convertJson.documentInfo.accompanyToRelevantInstitutionsAndOtherSupport,
          ),
          ...(convertJson.documentInfo.accompanyToRelevantInstitutionsAndOtherSupport === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            accompanyToRelevantInstitutionsAndOtherSupportReason: convertJson.documentInfo.accompanyToRelevantInstitutionsAndOtherSupportReason,
          }),
          supportLearnJapanese: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.supportLearnJapanese),
          ...(convertJson.documentInfo.supportLearnJapanese === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            supportLearnJapaneseReason: convertJson.documentInfo.supportLearnJapaneseReason,
          }),
          handleConsultationAndComplaint: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.handleConsultationAndComplaint),
          ...(convertJson.documentInfo.handleConsultationAndComplaint === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            handleConsultationAndComplaintReason: convertJson.documentInfo.handleConsultationAndComplaintReason,
          }),
          supportExchangeWithJapanesePeople: findLabelByValue(
            supportImplementationStatusList,
            convertJson.documentInfo.supportExchangeWithJapanesePeople,
          ),
          ...(convertJson.documentInfo.supportExchangeWithJapanesePeople === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            supportExchangeWithJapanesePeopleReason: convertJson.documentInfo.supportExchangeWithJapanesePeopleReason,
          }),
          supportFindJob: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.supportFindJob),
          ...(convertJson.documentInfo.supportFindJob === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            supportFindJobReason: convertJson.documentInfo.supportFindJobReason,
          }),
          conductRegularInterview: findLabelByValue(supportImplementationStatusList, convertJson.documentInfo.conductRegularInterview),
          ...(convertJson.documentInfo.conductRegularInterview === SUPPORT_IMPLEMENTATION_STATUS.NOT_SUPPORTED && {
            conductRegularInterviewReason: convertJson.documentInfo.conductRegularInterviewReason,
          }),
        },
        employeesInfo: convertJson.employeesInfo.map((employee: any) => ({
          employeeFullName: employee.employeeFullName,
          employeeNationality: nationalityList.find((item) => item.id == employee.employeeNationalityId)?.nationalityName || null,
          employeeBirthday: dayjs(employee.birthday).format(COMPACT_DATE_FORMAT),
          employeeGender: employee.employeeGender,
          employeeResidenceCardNumber: employee.employeeResidenceCardNumber || null,
          employeeZipCode: employee.zipCode ? employee.zipCode.slice(3, 0, "-") : null,
          employeeAddress: employee.fullAddress || null,
          employeeTelNumber: employee.telNumber || null,
        })),
      };
    }

    return jsonData;
  };

  const convertJsonDataForAnytime = (convertRequestData: TGetConvertRequestOutputDto) => {
    const convertJson = convertRequestData.json as unknown as any;
    const jsonData = {
      convertDate: dayjs(convertRequestData.createdAt).format(COMPACT_DATE_FORMAT),
      convertStatus: convertRequestData.status,
      category: findLabelByValue(documentType, convertRequestData.documentType),
      name: convertRequestData.documentName,
      company: {
        companyCode: convertRequestData.companyCode,
        companyName: convertRequestData.companyName,
        companyUserName: convertRequestData.companyUserName,
        companyNote: convertRequestData.companyNote || null,
      },
      account: {
        id: convertRequestData.accountId,
        password: secretKey ? decrypt(convertRequestData.accountPassword, secretKey) : "",
      },
      data: {
        documentInfo: {},
        employeesInfo: convertJson.employeesInfo.map((employee: any) => ({
          employeeFullName: employee.employeeFullName,
          employeeNickName: employee.employeeNickName,
          employeeGender: employee.employeeGender,
          employeeNationality: nationalityList.find((item) => item.id == employee.employeeNationalityId)?.nationalityName || null,
          employeeResidenceCardNumber: employee.employeeResidenceCardNumber,
          specificIndustry: employee.specificIndustryName,
          specificBusinessClassification: employee.specificBusinessClassificationName,
          employeeBirthday: employee.birthday ? dayjs(employee.birthday).format(COMPACT_DATE_FORMAT) : null,
          employeeZipCode: employee.zipCode ? employee.zipCode.slice(3, 0, "-") : null,
          employeeAddress: employee.fullAddress || null,
          employeeTelNumber: employee.telNumber || null,
        })),
      },
    } as any;

    if (convertRequestData.documentName === "employment_contract_modification") {
      jsonData.name = "雇用契約の変更に係る届出";
      jsonData.data.documentInfo = {
        modificationType: findLabelByValue(employmentContractModificationType, convertJson.documentInfo.modificationType),
        modifyContractDate: convertJson.documentInfo.modifyContractDate
          ? dayjs(convertJson.documentInfo.modifyContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        modifyContractModificationReasons: convertJson.documentInfo.modifyContractModificationReasons
          ? convertJson.documentInfo.modifyContractModificationReasons.map((reason: string) =>
              findLabelByValue(modifyContractModificationReason, reason),
            )
          : null,
        modifyContractChangeDispatchLocationReasons: convertJson.documentInfo.modifyContractChangeDispatchLocationReasons
          ? convertJson.documentInfo.modifyContractChangeDispatchLocationReasons.map((reason: string) =>
              findLabelByValue(modifyContractChangeDispatchLocationReasons, reason),
            )
          : null,
        modifyContractContent: convertJson.documentInfo.modifyContractContent || null,
        terminateContractDate: convertJson.documentInfo.terminateContractDate
          ? dayjs(convertJson.documentInfo.terminateContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        terminateContractReason: convertJson.documentInfo.terminateContractReason
          ? findLabelByValue(modifyContractTerminateReason, convertJson.documentInfo.terminateContractReason)
          : null,
        terminateContractReasonByCompany: convertJson.documentInfo.terminateContractReasonByCompany
          ? findLabelByValue(modifyContractTerminateReasonForCompany, convertJson.documentInfo.terminateContractReasonByCompany)
          : null,
        terminateContractOtherByCompany: convertJson.documentInfo.terminateContractOtherByCompany || null,
        terminateContractReasonByEmployee: convertJson.documentInfo.terminateContractReasonByEmployee
          ? findLabelByValue(modifyContractTerminateReasonForEmployee, convertJson.documentInfo.terminateContractReasonByEmployee)
          : null,
        terminateContractOtherReasonByEmployee: convertJson.documentInfo.terminateContractOtherReasonByEmployee || null,
        renewContractDate: convertJson.documentInfo.renewContractDate
          ? dayjs(convertJson.documentInfo.renewContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        renewContractContent: convertJson.documentInfo.renewContractContent || null,
        companyName: convertJson.documentInfo.companyName,
        companyFuriganaName: convertJson.documentInfo.companyFuriganaName || null,
        companySpecificIndustry:
          companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry || null,
        companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber || null,
        companyZipCode: convertJson.documentInfo.companyZipCode
          ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
          : null,
        companyProvince: convertJson.documentInfo.companyProvince,
        companyAddress: convertJson.documentInfo.companyAddress || null,
        companyTelNumber: convertJson.documentInfo.companyTelNumber || null,
        companySupporterName: convertJson.documentInfo.companySupporterName || null,
        companySupporterTelNumber: convertJson.documentInfo.companySupporterTelNumber || null,
      };
    }

    if (convertRequestData.documentName === "support_plan_modification") {
      jsonData.name = "支援計画変更に係る届出";
      jsonData.data.documentInfo = {
        modificationType: convertJson.documentInfo.modificationType,
        modifyContractDate: convertJson.documentInfo.modifyContractDate
          ? dayjs(convertJson.documentInfo.modifyContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        contractTermTarget: convertJson.documentInfo.contractTermTarget
          ? convertJson.documentInfo.contractTermTarget.map((target: string) => findLabelByValue(supportRecipient, target))
          : null,
        contractSpecifiedSkill: convertJson.documentInfo.contractSpecifiedSkill
          ? convertJson.documentInfo.contractSpecifiedSkill.map((skill: string) => findLabelByValue(supportSpecifiedSkill, skill))
          : null,
        contractTermOtherContent: convertJson.documentInfo.contractTermOtherContent || null,
        contractTermRegisteredSupportOrganization: convertJson.documentInfo.contractTermRegisteredSupportOrganization
          ? convertJson.documentInfo.contractTermRegisteredSupportOrganization.map((reason: string) => findLabelByValue(supportRegistered, reason))
          : null,
        contractTermOther: convertJson.documentInfo.contractTermOther,
        contentAfterChange: convertJson.documentInfo.contentAfterChange,
        companyName: convertJson.documentInfo.companyName,
        companyFuriganaName: convertJson.documentInfo.companyFuriganaName,
        companySpecificIndustry:
          companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry || null,
        companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber,
        companyZipCode: convertJson.documentInfo.companyZipCode
          ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
          : null,
        companyProvince: convertJson.documentInfo.companyProvince,
        companyAddress: convertJson.documentInfo.companyAddress || null,
        companyTelNumber: convertJson.documentInfo.companyTelNumber || null,
        companySupporterName: convertJson.documentInfo.companySupporterName || null,
        companySupporterTelNumber: convertJson.documentInfo.companySupporterTelNumber || null,
      };
    }

    if (convertRequestData.documentName === "support_outsourcing_contract_modification") {
      jsonData.name = "支援委託契約に係る届出";
      jsonData.data.documentInfo = {
        modificationType: findLabelByValue(supportOutsourcingContractModificationType, convertJson.documentInfo.modificationType),
        renewContractDate: convertJson.documentInfo.renewContractDate
          ? dayjs(convertJson.documentInfo.renewContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        renewContractContent: convertJson.documentInfo.renewContractContent || null,
        modifyContractDate: convertJson.documentInfo.modifyContractDate
          ? dayjs(convertJson.documentInfo.modifyContractDate).format(COMPACT_DATE_FORMAT)
          : null,
        modifyContractReason: convertJson.documentInfo.modifyContractReason
          ? findLabelByValue(supportContractSubcategoryReason, convertJson.documentInfo.modifyContractReason)
          : null,
        modifyContractContent: convertJson.documentInfo.modifyContractContent || null,
        terminateContractDate:
          convertJson.documentInfo.terminateContractDate || null
            ? dayjs(convertJson.documentInfo.terminateContractDate).format(COMPACT_DATE_FORMAT)
            : null,
        terminateContractMajorCategoryReason: convertJson.documentInfo.terminateContractMajorCategoryReason
          ? findLabelByValue(terminateContractMajorCategoryReason, convertJson.documentInfo.terminateContractMajorCategoryReason)
          : null,
        terminateContractSubcategoryReason: convertJson.documentInfo.terminateContractMajorCategoryReason
          ? findLabelByValue(terminateContractSubcategoryReason, convertJson.documentInfo.terminateContractSubcategoryReason)
          : null,
        terminateContractOtherReason: convertJson.documentInfo.terminateContractOtherReason || null,
        companyName: convertJson.documentInfo.companyName || null,
        companyFuriganaName: convertJson.documentInfo.companyFuriganaName || null,
        companySpecificIndustry:
          companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry || null,
        companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber || null,
        companyZipCode: convertJson.documentInfo.companyZipCode
          ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
          : null,
        companyProvince: convertJson.documentInfo.companyProvince,
        companyAddress: convertJson.documentInfo.companyAddress || null,
        companyTelNumber: convertJson.documentInfo.companyTelNumber || null,
        companySupporterName: convertJson.documentInfo.companySupporterName || null,
        companySupporterTelNumber: convertJson.documentInfo.companySupporterTelNumber || null,
      };
    }

    return jsonData;
  };

  const convertJsonDataForRetirement = (convertRequestData: TGetConvertRequestOutputDto) => {
    const convertJson = convertRequestData.json as unknown as any;
    const jsonData = {
      convertDate: dayjs(convertRequestData.createdAt).format(COMPACT_DATE_FORMAT),
      convertStatus: convertRequestData.status,
      category: findLabelByValue(documentType, convertRequestData.documentType),
      name: convertRequestData.documentName,
      company: {
        companyCode: convertRequestData.companyCode,
        companyName: convertRequestData.companyName,
        companyUserName: convertRequestData.companyUserName,
        companyNote: convertRequestData.companyNote || null,
      },
      account: {
        id: convertRequestData.accountId,
        password: secretKey ? decrypt(convertRequestData.accountPassword, secretKey) : "",
      },
    } as any;

    if (convertRequestData.documentName === ERetirementDocumentName.RETIREMENT_ACCEPTING_WORKERS) {
      jsonData.name = "受入れ困難に係る届出";
      const zipCode = convertJson.employeesInfo.zipCode;
      let formattedZipCode = null;
      if (zipCode) {
        formattedZipCode = `${zipCode.slice(0, 3)}-${zipCode.slice(3)}`;
      }

      const { province, address, municipality } = convertJson.employeesInfo;
      const fullAddress = getFullAddress({ province: province, municipality: municipality, address: address });

      jsonData.data = {
        documentInfo: {
          companyName: convertJson.documentInfo.companyName,
          companyFuriganaName: convertJson.documentInfo.companyFuriganaName || null,
          companySpecificIndustry:
            companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry ||
            EMPTY_STR.TEXT,
          companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber,
          companyZipCode: convertJson.documentInfo.companyZipCode
            ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
            : null,
          companyProvince: convertJson.documentInfo.companyProvince,
          companyAddress: convertJson.documentInfo.companyAddress,
          companyTelNumber: convertJson.documentInfo.companyTelNumber,
          companySupporterName: convertJson.documentInfo.supporterName || null,
          companySupporterTelNumber: convertJson.documentInfo.supporterTelNumber || null,
          reasonType: convertJson.documentInfo.reasonType ? findLabelByValue(retirementReasonTypeOptions, convertJson.documentInfo.reasonType) : null,
          changeContractCategoryType: convertJson.documentInfo.changeContractCategoryType
            ? findLabelByValue(modifyContractTerminateReasonForEmployee, convertJson.documentInfo.changeContractCategoryType)
            : null,
          changeContractCategoryContent: convertJson.documentInfo.changeContractCategoryContent || null,
          changeContractDateOfOccurrence: convertJson.documentInfo.changeContractDateOfOccurrence
            ? dayjs(convertJson.documentInfo.changeContractDateOfOccurrence).format(COMPACT_DATE_FORMAT)
            : null,
          changeContractSummary: convertJson.documentInfo.changeContractSummary || null,
          convenienceCategoryType:
            findLabelByValue(modifyContractTerminateReasonForEmployee, convertJson.documentInfo.convenienceCategoryType) || null,
          convenienceCategoryContent: convertJson.documentInfo.convenienceCategoryContent || null,
          convenienceDateOfOccurrence: convertJson.documentInfo.convenienceDateOfOccurrence
            ? dayjs(convertJson.documentInfo.convenienceDateOfOccurrence).format(COMPACT_DATE_FORMAT)
            : null,
          convenienceSummary: convertJson.documentInfo.convenienceSummary || null,
          currentSituation: convertJson.documentInfo.currentSituation
            ? findLabelByValue(currentSituationOptions, convertJson.documentInfo.currentSituation)
            : null,
          planToContinueActivity: convertJson.documentInfo.planToContinueActivity
            ? findLabelByValue(planToContinueActivityOptions, convertJson.documentInfo.planToContinueActivity)
            : null,
          planToContinueActivityContent: convertJson.documentInfo.planToContinueActivityContent || null,
          measuresTakenType: convertJson.documentInfo.measuresTakenType
            ? findLabelByValue(measureOptions, convertJson.documentInfo.measuresTakenType)
            : null,
          measuresTakenContent: convertJson.documentInfo.measuresTakenContent || null,
          specificCircumstances: convertJson.documentInfo.specificCircumstances
            ? findLabelByValue(specificCircumstanceOptions, convertJson.documentInfo.specificCircumstances)
            : null,
          specificCircumstanceContent: convertJson.documentInfo.specificCircumstanceContent
            ? convertJson.documentInfo.specificCircumstanceContent
            : null,
          anyConsultation: convertJson.documentInfo.anyConsultation
            ? findLabelByValue(anyConsultationOptions, convertJson.documentInfo.anyConsultation)
            : null,
          anyConsultationContent: convertJson.documentInfo.anyConsultationContent ? convertJson.documentInfo.anyConsultationContent : null,
          plansToChangeJob: convertJson.documentInfo.plansToChangeJob
            ? findLabelByValue(planToChangeJobOptions, convertJson.documentInfo.plansToChangeJob)
            : null,
          plansToChangeJobContent: convertJson.documentInfo.plansToChangeJobContent ? convertJson.documentInfo.plansToChangeJobContent : null,
          supportType: convertJson.documentInfo.supportType ? findLabelByValue(supportTypeOptions, convertJson.documentInfo.supportType) : null,
          supportContent: convertJson.documentInfo.supportContent ? convertJson.documentInfo.supportContent : null,
          reasonReturnHome: convertJson.documentInfo.reasonReturnHome
            ? findLabelByValue(reasonReturnHomeOptions, convertJson.documentInfo.reasonReturnHome)
            : null,
          reasonReturnHomeContent: convertJson.documentInfo.reasonReturnHomeContent ? convertJson.documentInfo.reasonReturnHomeContent : null,
          planDateReturn: convertJson.documentInfo.planDateReturn
            ? findLabelByValue(planDateReturnOptions, convertJson.documentInfo.planDateReturn)
            : null,
          planDateReturnContent: convertJson.documentInfo.planDateReturnContent ? convertJson.documentInfo.planDateReturnContent : null,
          planToMove: convertJson.documentInfo.planToMove ? findLabelByValue(planToMoveOptions, convertJson.documentInfo.planToMove) : null,
          planToMoveContent: convertJson.documentInfo.planToMoveContent ? convertJson.documentInfo.planToMoveContent : null,
          telNumber: convertJson.documentInfo.telNumber || null,
          contractName: convertJson.documentInfo.contractName || null,
          planedDateOfReturn: convertJson.documentInfo.planedDateOfReturn
            ? dayjs(convertJson.documentInfo.planedDateOfReturn).format(COMPACT_DATE_FORMAT)
            : null,
          providedLegalProtection:
            convertJson.documentInfo.providedLegalProtection?.map((item: any) => {
              return findLabelByValue(providedLegalProtectionOptions, item);
            }) || null,
        },
        employeesInfo: {
          employeeFullName: getFullName({
            lastName: convertJson.employeesInfo?.lastName,
            middleName: convertJson.employeesInfo?.middleName,
            firstName: convertJson.employeesInfo?.firstName,
          }),
          employeeNationality: nationalityList.find((item) => item.id == convertJson.employeesInfo.nationalityId)?.nationalityName || EMPTY_STR.TEXT,
          employeeBirthday: dayjs(convertJson.employeesInfo.birthday).format(COMPACT_DATE_FORMAT),
          employeeGender: convertJson.employeesInfo.gender,
          employeeResidenceCardNumber: convertJson.employeesInfo.residenceCode || null,
          employeeZipCode: formattedZipCode,
          employeeAddress: fullAddress || null,
        },
      };
    }

    if (convertRequestData.documentName === ERetirementDocumentName.RETIREMENT_EMPLOYMENT_CONTRACT) {
      jsonData.name = "特定技能雇用契約に係る届出";
      jsonData.data = {
        documentInfo: {
          companyName: convertJson.documentInfo.companyName,
          companyFuriganaName: convertJson.documentInfo.companyFuriganaName || null,
          companySpecificIndustry:
            companySpecificIndustryList.find((item) => item.id == convertJson.documentInfo.companySpecificIndustryId)?.companySpecificIndustry ||
            EMPTY_STR.TEXT,
          companyIdentificationNumber: convertJson.documentInfo.companyIdentificationNumber,
          companyZipCode: convertJson.documentInfo.companyZipCode
            ? convertJson.documentInfo.companyZipCode.slice(0, 3) + "-" + convertJson.documentInfo.companyZipCode.slice(3)
            : null,
          companyProvince: convertJson.documentInfo.companyProvince,
          companyAddress: convertJson.documentInfo.companyAddress,
          companyTelNumber: convertJson.documentInfo.companyTelNumber,
          companySupporterName: convertJson.documentInfo.supporterName || null,
          companySupporterTelNumber: convertJson.documentInfo.supporterTelNumber || null,
          // reasonType: convertJson.documentInfo.reasonType ? findLabelByValue(retirementReasonTypeOptions, convertJson.documentInfo.reasonType) : null,
          terminateContractDate: dayjs(convertJson.documentInfo.terminateContractDate).format(COMPACT_DATE_FORMAT),
          terminateContractReason: "特定技能所属機関の都合",
          terminateContractReasonByCompany: convertJson.documentInfo.terminateContractReasonByCompany
            ? findLabelByValue(modifyContractTerminateReasonForCompanyOptions, convertJson.documentInfo.terminateContractReasonByCompany)
            : null,
          terminateContractOtherByCompany: convertJson.documentInfo.terminateContractOtherByCompany,
          terminateContractReasonByEmployee: convertJson.documentInfo.terminateContractReasonByEmployee
            ? findLabelByValue(modifyContractTerminateReasonForEmployeeOptions, convertJson.documentInfo.terminateContractReasonByEmployee)
            : null,
          terminateContractOtherReasonByEmployee: convertJson.documentInfo.terminateContractOtherReasonByEmployee,
        },
        employeesInfo: {
          employeeFullName: getFullName({
            lastName: convertJson.employeesInfo?.lastName,
            middleName: convertJson.employeesInfo?.middleName,
            firstName: convertJson.employeesInfo?.firstName,
          }),
          employeeNationality:
            nationalityList.find((item) => item.id == convertJson.employeesInfo.employeeNationalityId)?.nationalityName || EMPTY_STR.TEXT,
          employeeBirthday: dayjs(convertJson.employeesInfo.birthday).format(COMPACT_DATE_FORMAT),
          employeeGender: convertJson.employeesInfo.gender,
          employeeResidenceCardNumber: convertJson.employeesInfo.residenceCode || null,
          employeeZipCode: convertJson.employeesInfo.zipCode ? convertJson.employeesInfo.zipCode.slice(3, 0, "-") : null,
          employeeAddress: convertJson.employeesInfo.address || null,
        },
      };
    }
    return jsonData;
  };

  const [dataJSON, setDataJSON] = useState(beautify(JSON.stringify(convertJsonData(convertRequestData)) ?? "", { indent_size: 2 }));

  return (
    <Stack gap={1}>
      <Typography variant="sub16Bold">JSONデータ（外部連携用）</Typography>
      <Stack gap={1.5} py={1.5} bgcolor={background.white} direction="row" alignItems="flex-end" justifyContent="center">
        <StyledCodeMirror id="ConversionJsonTextArea" value={dataJSON} readOnly />
        <Stack gap={1.5}>
          <Copyable text={dataJSON}>
            <Button sx={{ width: 96 }}>コピー</Button>
          </Copyable>
          <Button id="ConversionIntegrationButton" disabled sx={{ width: 96 }}>
            連携
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const StyledCodeMirror = styled("textarea")`
  padding: 8px;
  max-width: 60%;
  font-size: 16px;
  width: 100%;
  height: 300px;
  border-radius: ${rounded.sm};
  border: 1px solid ${divider.middle};
  resize: none;
  :focus {
    outline: none;
  }
  :read-only {
    background: ${background.disable};
  }
`;
