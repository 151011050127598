import { Button } from "@/components/elements/Button";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { useVisible } from "@/hooks/useVisible";
import { background, button, divider, green, text } from "@/theme/colors";
import { dayjs } from "@/utils/dayjs";
import { Box, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { rounded } from "@/theme/variables";
import { updateLocalGovernmentCounterApi, useGetLocalGovernmentCounterList } from "@/api/services/global/master";
import { TGetLocalGovernmentCounterListOutputDto } from "@/api/services/global/master/dtos/get-master-local-government-counter-list.dto";
import { LoadingPage } from "@/components/elements/Loading/Loading";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { HiddenLocalGovernmentCounterModal } from "@/features/master-data/components/LocalGovernmentCounter/HiddenLocalGovernmentCounterModal";
import { LocalGovernmentCounterFormModal } from "@/features/master-data/components/LocalGovernmentCounter/LocalGovernmentCounterFormModal";
import { MASTER_DATA_TYPE_TITLE } from "@/features/master-data/constants/masterDataType";
import { AppRoutes } from "@/routes/config";
import { showError } from "@/utils/error";
import { toNumber } from "@/utils/number";
import { toast } from "@/utils/toast";
import { useEffect } from "react";
import { ConfirmLeavePage } from "@/components/commons/ConfirmLeavePage";

export const MasterDataLocalGovernmentCounter = () => {
  const formModal = useVisible();
  const [hiddenLocalGovernmentCounter, setHiddenLocalGovernmentCounter] = useState<TGetLocalGovernmentCounterListOutputDto>();
  const [selectedLocalGovernmentCounter, setSelectedLocalGovernmentCounter] = useState<TGetLocalGovernmentCounterListOutputDto>();
  const [enableSaveOrderBtn, setEnableOrderBtn] = useState<boolean>(false);
  const { data, isLoading } = useGetLocalGovernmentCounterList();

  const [rows, setRows] = useState<TGetLocalGovernmentCounterListOutputDto[]>(data);

  useEffect(() => {
    if (data && data.length > 0) setRows(data);
  }, [data]);

  const handleSaveOrder = async () => {
    try {
      const rs = await updateLocalGovernmentCounterApi({
        localGovernmentCounters: rows.map((item) => ({
          id: toNumber(item.id),
          officeName: item.officeName,
          bmOfficeRenewVisaCsvCode: item.bmOfficeRenewVisaCsvCode,
          targetMunicipalities: item.targetMunicipalities,
          targetProvinces: item.targetProvinces,
        })),
      });
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      setEnableOrderBtn(false);
    } catch (error) {
      showError(error);
    }
  };

  // Handle row dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !result.source || result.source.index === result.destination.index) return;
    const reorderedRows = Array.from(rows);
    const [removed] = reorderedRows.splice(result.source.index, 1);
    reorderedRows.splice(result.destination.index, 0, removed);
    setRows(reorderedRows);
    setEnableOrderBtn(true);
  };

  if (isLoading || rows.length === 0) return <LoadingPage />;

  return (
    <>
      <TopPage title={"「" + MASTER_DATA_TYPE_TITLE.LOCAL_GOVERNMENT_COUNTER + "」の編集"} backUrl={AppRoutes.masterData} />
      <Stack gap={3}>
        <Stack direction="row" justifyContent="flex-end" gap={3}>
          <Button size="sm" variant="outline" onClick={() => handleSaveOrder()} disabled={!enableSaveOrderBtn}>
            オーダーを保存する
          </Button>
          <Button size="sm" variant="outline" onClick={() => formModal.open()}>
            項目追加
          </Button>
        </Stack>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tableRows" direction="vertical">
            {(provided) => (
              <StyledTable {...provided.droppableProps} ref={provided.innerRef}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>受領官署名</TableCell>
                      <TableCell>期間更新CSVのコード（列BM）</TableCell>
                      <TableCell>対象の都道府県</TableCell>
                      <TableCell>対象の市区町村（あれば）</TableCell>
                      <TableCell>最終更新日</TableCell>
                      <TableCell sx={{ position: "sticky", textAlign: "center !important", right: "100px", bgcolor: background.white }}>
                        有効／無効
                      </TableCell>
                      <TableCell sx={{ position: "sticky", textAlign: "center !important", right: "0", bgcolor: background.white }}>編集</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                        {(provided) => (
                          <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <TableCell sx={{ minWidth: "280px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.officeName || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "280px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.bmOfficeRenewVisaCsvCode || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.targetProvinces.length > 0 ? row.targetProvinces.map((item) => item.prefectureName).join(", ") : EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.targetMunicipalities && row.targetMunicipalities.length > 0
                                  ? row.targetMunicipalities.map((item) => item.municipalityName).join(", ")
                                  : EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "150px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.updatedAt ? dayjs(row.updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center !important",
                                minWidth: "120px",
                                position: "sticky",
                                right: "100px",
                                bgcolor: background.white,
                              }}
                            >
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                  setHiddenLocalGovernmentCounter(row);
                                }}
                              >
                                {row.isHidden ? "有効化" : "無効化"}
                              </Button>
                            </TableCell>
                            <TableCell
                              sx={{
                                minWidth: "100px",
                                textAlign: "center !important",
                                paddingX: "4px !important",
                                position: "sticky",
                                right: "0px",
                                bgcolor: background.white,
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() => {
                                  setSelectedLocalGovernmentCounter(row);
                                  formModal.open();
                                }}
                              >
                                編集
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </StyledTable>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>

      {formModal.visible && (
        <LocalGovernmentCounterFormModal
          onClose={() => {
            formModal.close();
            setSelectedLocalGovernmentCounter(undefined);
          }}
          isOpen={formModal.visible}
          selectedLocalGovernmentCounter={selectedLocalGovernmentCounter}
        />
      )}

      <HiddenLocalGovernmentCounterModal
        hiddenLocalGovernmentCounter={hiddenLocalGovernmentCounter}
        setHiddenLocalGovernmentCounter={setHiddenLocalGovernmentCounter}
      />
      <ConfirmLeavePage shouldBlock={enableSaveOrderBtn}></ConfirmLeavePage>
    </>
  );
};

const StyledTable = styled(Box)`
  border-radius: ${rounded.sm};
  overflow: auto;
  max-height: 575px;
  border-top: 1px solid ${divider.middle};
  border-bottom: 1px solid ${divider.middle};
  border-right: 1px solid ${divider.middle};
  background-color: ${background.white};
  table {
    border-collapse: separate;
  }
  th,
  td {
    border-left: 1px solid ${divider.middle};
  }

  * {
    box-shadow: none;
  }
  .MuiToolbar-dense {
    display: none;
  }
  .MuiTableCell-head {
    padding: 8px !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    position: sticky;
    background-color: white;
    z-index: 500;
    top: 0;
  }

  .Mui-TableHeadCell-Content-Labels {
    > span {
      margin-left: 4px;
      img {
        opacity: 0.2;
      }
      .MuiBadge-badge {
        display: none;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  // Default
  .MuiTableRow-root {
    background-color: ${background.white};
    box-shadow: none;
    cursor: initial;
    .MuiTableCell-root {
      padding: 4px;
      border-color: ${divider.middle};
      opacity: 1;
    }
    &:not(.MuiTableRow-head):last-child {
      .MuiTableCell-root {
        border-bottom: 0;
      }
    }
  }
  // Hover
  .MuiTableRow-root:not(.MuiTableRow-head):hover {
    &,
    td {
      cursor: pointer;
      background-color: ${background.white};
      &::after {
        background-color: ${background.primary};
      }
    }
  }
  // Click/Pressed
  .Mui-selected {
    background-color: ${background.white} !important;
    td::after {
      background: ${background.secondary}!important;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiPaper-root > .MuiBox-root {
    display: none;
  }

  .MuiCheckbox-root {
    color: ${button.primary}!important;
    height: 24px;
    width: 24px;
    padding: 0;
  }

  // Disabled Row
  .MuiTableCell-root.disabled {
    opacity: 1;
    background: ${background.disable}!important;
    pointer-events: none;
  }

  // added row
  .MuiTableCell-root.added {
    background: ${green[99]}!important;
    &::after {
      background: unset !important;
    }
  }

  // Failed row
  .MuiTableCell-root.failed {
    background: ${background.error}!important;
    &::after {
      background: unset !important;
    }
  }

  // Clickable row
  .MuiTableCell-root.clickable {
    cursor: pointer;
  }
`;
