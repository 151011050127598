import { useFormField } from "@/components/elements/FormField";
import { FC, useEffect, useState } from "react";
import { Editor as DEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import formatBoldIMG from "@/assets/images/format_bold.png";
import formatClearIMG from "@/assets/images/format_clear.png";
import formatListBullIMG from "@/assets/images/format_list_bulleted.png";
import formatListNumIMG from "@/assets/images/format_list_numbered.png";
import formatQuoteIMG from "@/assets/images/format_quote.png";
import formatStrikeIMG from "@/assets/images/strikethrough_s.png";
import { Clickable } from "@/components/elements/Clickable";
import { hexToRgba } from "@/utils/pieces";
import { isUrl } from "@/utils/url";
import { background, divider } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import styled from "@emotion/styled";
import { Box, css } from "@mui/material";
import { ContentState, EditorState, Modifier, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export type TEditorProps = {
  value?: string;
  placeholder?: string;
  nonControl?: boolean;
  onChange?: (v: string) => void;
};

export const Editor: FC<TEditorProps> = ({ nonControl = false, placeholder = "入力してください", onChange, value }) => {
  const createEditorStateFromHtml = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  const { field } = useFormField(nonControl);
  const [editorState, setEditorState] = useState(createEditorStateFromHtml(field?.value ?? value ?? ""));

  useEffect(() => {
    if (!(field?.value ?? value)) {
      setEditorState(EditorState.createEmpty());
    }
  }, [value, field?.value]);

  const onEditorStateChange = (v: EditorState) => {
    setEditorState(v);
    updateValue();
  };

  const handleClear = () => {
    setEditorState((cur) => {
      const newState = EditorState.push(cur, ContentState.createFromText(""), "remove-range");
      return newState;
    });
    updateValue();
  };

  const updateValue = () => {
    let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    console.log(htmlContent);
    if (isContentEmpty(htmlContent)) {
      htmlContent = "";
    }
    onChange?.(htmlContent);
    field?.onChange(htmlContent);
  };

  const isContentEmpty = (htmlContent: string) => {
    const elm = document.createElement("div");
    elm.innerHTML = htmlContent;
    const textContent = elm.textContent?.replace(/\n/g, "");
    elm.remove();
    return !textContent;
  };

  const handlePastedText = (text: string, html: string, state: EditorState, onChange: (v: EditorState) => void) => {
    if (!text) return true;
    // if (isUrl(text) && !isInternalTenantLink(text)) {
    //   toast.error(PREVENT_URL);
    //   return true;
    // }
    const currentContent = state.getCurrentContent();
    const selection = editorState.getSelection();
    currentContent.createEntity("LINK", "MUTABLE", { url: text });
    const entityKey = currentContent.getLastCreatedEntityKey();
    const contentState = Modifier.insertText(currentContent, selection, text, undefined, isUrl(text) ? entityKey : undefined);
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
    return true;
  };

  return (
    <EditorWrap>
      <DEditor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onBlur={field?.onBlur}
        placeholder={placeholder}
        toolbarCustomButtons={[<ClearEditor onClick={handleClear} />]}
        handlePastedText={handlePastedText}
        toolbar={{
          options: ["inline", "blockType", "list"],
          inline: {
            options: ["bold", "strikethrough"],
            bold: { icon: formatBoldIMG },
            strikethrough: { icon: formatStrikeIMG },
          },
          blockType: {
            inDropdown: false,
            options: ["Blockquote"],
            className: "block-type",
          },
          list: {
            options: ["unordered", "ordered"],
            unordered: { icon: formatListBullIMG },
            ordered: { icon: formatListNumIMG },
          },
        }}
      />
    </EditorWrap>
  );
};

type TClearEditorProps = {
  onClick: () => void;
};

const ClearEditor: FC<TClearEditorProps> = ({ onClick }) => {
  return (
    <Clickable className="rdw-option-wrapper" onClick={onClick}>
      <img src={formatClearIMG} alt="clear" />
    </Clickable>
  );
};

const EditorWrap = styled(Box)`
  .rdw-editor-toolbar {
    background-color: ${background.disable};
    border: 1px solid ${divider.middle};
    border-radius: 0;
    border-top-left-radius: ${rounded.sm};
    border-top-right-radius: ${rounded.sm};
    margin-bottom: 0;
  }
  .rdw-editor-main {
    background-color: ${background.white};
    border: 1px solid ${divider.middle};
    border-bottom-left-radius: ${rounded.sm};
    border-bottom-right-radius: ${rounded.sm};
    padding: 8px;
    min-height: 327px;
    ${css(typography.body14)}
    .public-DraftStyleDefault-block {
      margin: 0;
    }
    .rdw-link-decorator-wrapper {
      display: inline-block;
      a {
        & + img {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
  .rdw-option-wrapper {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;
    box-shadow: none !important;
    color: transparent;
    background-color: transparent;
    &[aria-selected="true"] {
      background-color: ${hexToRgba(background.dark, 0.2)};
    }
  }
  .block-type {
    .rdw-option-wrapper {
      &:nth-of-type(1) {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(${formatQuoteIMG}) no-repeat;
          z-index: 0;
        }
      }
    }
  }
`;
